<template>
  <div class="row m-0 abs">
    <div class="col-12">
      <div class="row">
        <div class="col-12 bold smallheading my-4">
          Add Investmemts
        </div>
        <div class="col-12 col-md-6 mb-2">
          <TextField
            type="text"
            color="gold"
            placeholder="Description"
            v-model="investment.description"
            id="description"
            label="Description:"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <SelectAll
            v-model="investment.type"
            id="type"
            :options="investmentAssetType"
            displayValue="description"
            placeholder="Investment Type"
            color="gold"
            label="Select Type Of Investment:"
            width="full"
            :goUp="true"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <SelectAll
            v-model="investment.currency"
            id="currency"
            :options="currencies"
            displayValue="description"
            placeholder="Currency"
            width="full"
            color="gold"
            label="Select Currency:"
            :goUp="true"
          />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <CurrencyField
            type="text"
            placeholder="0.00"
            id="amount"
            color="gold"
            v-model="investment.amount"
            :currencySymbol="investment.currency.symbol"
            label="Enter Amount:"
          />
        </div>
        <div class="col-12 bold smallheading my-4">
          Total: {{defaultCurrency}}{{parseFloat(Math.round((calculatedLocalAmount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col-12 my-4" v-if="isEdit">
          <Button color="green" btnText="Update" icon="arrow" @buttonClicked="update" class="mb-1" >
            <IconArrowForward color="gold" />
          </Button>
          <Button color="transparent" btnText="Cancel" @buttonClicked="cancel" class="mb-1 ms-3" >
          </Button>
        </div>
        <div class="col-12 my-4" v-else>
          <Button color="green" btnText="Save" icon="arrow" @buttonClicked="save" class="mb-1" :disabled="investment.amount < 0.01" >
            <IconArrowForward color="gold" />
          </Button>
        </div>
      </div>
      <div class="row" v-if="investmentAssets.length > 0">
        <div class="col-12 bold smallheading my-4">
          Investments
        </div>
      </div>
      <div class="row py-2 border-bottom align-items-center" v-for="(asset, key) in investmentAssets" :key="key">
        <div class="col-12">
          <div class="row d-none d-md-flex align-items-center">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{asset.description}}
                </div>
                <div class="col-12 font12">
                  {{ displayDescription(asset.investmentAssetType) }}
                </div>
                <div class="col-12 font12">
                  {{asset.currency.symbol}} {{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" btnText="EDIT" icon="arrow" size="xsmall" @buttonClicked="editIndividual(key, asset)">
                <IconEdit size="size16" />
              </Button>
            </div>
            <div class="col-auto text-end bold">
              {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="small" @buttonClicked="showDelete(key, asset)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
          <div class="row d-md-none">
            <div class="col">
              <div class="row">
                <div class="col-12 bold mb-1">
                  {{asset.description}}
                </div>
                <div class="col-12 font12">
                  {{ displayDescription(asset.investmentAssetType) }}
                </div>
                <div class="col-12 font12">
                  {{asset.currency.symbol}} {{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                </div>
                <div class="col-12 bold mt-2">
                  {{defaultCurrency}}{{parseFloat(Math.round((asset.amount) * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }}
                </div>
              </div>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="editIndividual(key, asset)">
                <IconEdit />
              </Button>
            </div>
            <div class="col-auto text-end bold p-0">
              <Button color="transparent" icon="arrow" size="xxsmall" @buttonClicked="showDelete(key, asset)">
                <ios-trash-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Alert v-if="isDelete" title="Are you sure you want to delete this investment?" :message="investment.description" @close="closeDelete" button1="Delete" button2="Cancel" align="center" @doAction2="closeDelete" @doAction1="deleteItem" key="alert"></Alert>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Alert: defineAsyncComponent(() => import('../../../components/Alert.vue')),
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    'ios-trash-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-trash.vue'))
  },
  name: 'AddInvestments',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      investment: {
        description: '',
        type: {},
        currency: {},
        total: null,
        amount: null
      },
      isEdit: false,
      isDelete: false,
      currentKey: null,
      investmentAssetType: [{
        value: 'BONDS',
        description: 'Bonds'
      }, {
        value: 'DEBENTURES',
        description: 'Debentures'
      }, {
        value: 'DERIVATIVES',
        description: 'Derivatives'
      }, {
        value: 'FUTURES',
        description: 'Futures'
      }, {
        value: 'GOVERNMENT_BONDS',
        description: 'Government Bonds'
      }, {
        value: 'GOVERNMENT_STOCKS',
        description: 'Government Stocks'
      }, {
        value: 'INVESTMENTS_IN_COMMODITY_MARKET',
        description: 'Investments in Commodity Market'
      }, {
        value: 'OPTIONS',
        description: 'Options'
      }, {
        value: 'PROPERTY_UNIT_TRUSTS',
        description: 'Property Unity Trusts'
      }, {
        value: 'SECURITIES',
        description: 'Securities'
      }, {
        value: 'SHARES_LISTED',
        description: 'Shares (Companies listed on Stock Exchange)'
      }, {
        value: 'SHARES_UNLISTED',
        description: 'Shares (Unlisted Companies and Mutual Funds)'
      }, {
        value: 'STOCKS_IN_STOCK_EXCHANGE',
        description: 'Stocks (in Stock Exchange)'
      }, {
        value: 'UNIT_TRUST',
        description: 'Unit Trusts'
      }, {
        value: 'WARRANTS',
        description: 'Warrants'
      }, {
        value: 'OTHER_INVESTMENTS_ASSET_TYPE',
        description: 'Other Investments'
      }]
    }
  },
  computed: {
    ...mapGetters([
      'exchangeRate',
      'currencies',
      'investmentAssets'
    ]),
    calculatedLocalAmount () {
      if (Object.keys(this.investment.currency).length !== 0 && this.investment.amount) {
        const index = this.exchangeRate.findIndex(x => x.currencyID === this.investment.currency.currencyID)
        const rate = this.exchangeRate[index].rate
        if (typeof rate !== 'undefined') {
          return (parseFloat((1 / rate) * this.investment.amount)).toFixed(2)
        } else {
          return (parseFloat((1 / 1) * this.investment.amount)).toFixed(2)
        }
      }
      return (0).toFixed(2)
    }
  },
  methods: {
    ...mapActions([
      'addToInvestments',
      'editInvestments',
      'deleteInvestmentsItem',
      'clearInvestments'
    ]),
    displayDescription (val) {
      if (typeof val === 'string' || val instanceof String) {
        const splitItem = val.split('_')
        const arr = []
        splitItem.forEach(item => {
          const lowercase = item.toLowerCase()
          const caps = lowercase.charAt(0).toUpperCase() + lowercase.slice(1)
          arr.push(caps)
        })
        const str = arr.slice(0, arr.length).join(' ')
        return str
      }
    },
    showDelete (key, val) {
      this.investment = val
      this.currentKey = key
      this.isDelete = true
    },
    closeDelete  () {
      this.investment = {
        description: '',
        type: {},
        currency: {},
        total: null,
        amount: null
      }
      this.currentKey = null
      this.isDelete = false
    },
    deleteItem () {
      this.deleteInvestmentsItem(this.currentKey)
      this.investment = {
        description: '',
        type: {},
        currency: {},
        total: null,
        amount: null
      }
      this.currentKey = null
      this.isDelete = false
    },
    cancel () {
      this.investment = {
        description: '',
        type: {},
        currency: {},
        total: null,
        amount: null
      }
      this.isEdit = false
      this.currentKey = null
    },
    editIndividual (key, val) {
      this.isEdit = true
      this.investmentAssetType.forEach((item) => {
        if (item.value === val.investmentAssetType) {
          this.investment.type = item
        }
      })
      this.investment.currency = val.currency
      this.investment.description = val.description
      this.investment.amount = val.amount
      this.currentKey = key
    },
    async update () {
      this.investment.total = parseFloat(this.calculatedLocalAmount)
      const saveInvestment = {
        key: '',
        item: {
          description: '',
          estimatedRandValue: 0,
          amount: 0,
          investmentAssetType: '',
          currency: {}
        }
      }
      saveInvestment.key = this.currentKey
      saveInvestment.item.investmentAssetType = this.investment.type.value
      saveInvestment.item.description = this.investment.description
      saveInvestment.item.amount = this.investment.amount
      saveInvestment.item.estimatedRandValue = this.investment.total
      saveInvestment.item.currency = this.investment.currency
      this.editInvestments(saveInvestment)
      this.investment = {
        description: '',
        type: {},
        currency: {},
        total: null,
        amount: null
      }
      this.isEdit = false
      this.currentKey = null
    },
    async save () {
      this.investment.total = parseFloat(this.calculatedLocalAmount)
      const saveInvestment = {
        description: '',
        estimatedRandValue: 0,
        amount: 0,
        investmentAssetType: '',
        currency: {}
      }
      saveInvestment.investmentAssetType = this.investment.type.value
      saveInvestment.description = this.investment.description
      saveInvestment.amount = this.investment.amount
      saveInvestment.estimatedRandValue = this.investment.total
      saveInvestment.currency = this.investment.currency
      this.addToInvestments(saveInvestment)
      this.investment = {
        description: '',
        type: {},
        currency: {},
        total: null,
        amount: null
      }
    }
  }
}
</script>

<style scoped>
.smallheading {
  color: var(--green-color);
  font-family: "quicksand_medium", Sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
}
</style>
